import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ImageBanner from '../components/ImageBanner';
import ModularBlocks from '../components/ModularBlocks';

const ProjectPageTemplate = ({ data: { datoCmsProject } }) => {
  const {
    locale,
    slugLocales,
    seoMetaTags,
    title,
    featuredImage,
    bannerHeading,
    bannerText,
    modularBlocks,
  } = datoCmsProject;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsProject}
    >
      <main>
        <ImageBanner
          title={title}
          heading={bannerHeading}
          image={featuredImage}
          text={bannerText}
        />
        <ModularBlocks items={modularBlocks} locale={locale} />
      </main>
    </Layout>
  );
};

export const ProjectPageTemplateQuery = graphql`
  query ProjectPageTemplateQuery($id: String!) {
    datoCmsProject(id: { eq: $id }) {
      locale
      slugLocales: _allSlugLocales {
        locale
        value
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      featuredImage {
        ...SubDesktopImageBannerFragment
      }
      bannerHeading
      bannerText
      modularBlocks {
        ...AccordionModularBlockFragment
        ...BackgroundImageCtaModularBlockFragment
        ...ContactFormModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...CreateTradeAccountFormModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedCustomerPhotosModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedPointsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...IconAccordionModularBlockFragment
        ...IconContentBlocksModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageGalleryModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...NumberedContentListModularBlockFragment
        ...ProjectListingModularBlockFragment
        ...ReviewsModularBlockFragment
        ...TabsModularBlockFragment
        ...TestimonialsModularBlockFragment
        ...TradeSignUpModularBlockFragment
      }
      ...LinkFragment
    }
  }
`;

export default ProjectPageTemplate;
